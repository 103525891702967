<fieldset class="form-horizontal">
  <h3 class="text-thin">Order Details</h3>
  <div class="row">
    <div class="col-sm-8">
      <react-component
        ng-if="$ctrl.order.type === 'moving-move'"
        name="MovingSubtypeFormGroup"
        props="{
          selection: $ctrl.order.subtype,
          onSelect: $ctrl.onSelectSubtype,
        }"
      />
      <react-component
        ng-if="$ctrl.order.type !== 'selfstorage-shuttle' || $ctrl.order.id"
        name="OrderRegionFormGroup"
        props="{
          selection: $ctrl.order.region,
          onSelect: $ctrl.onSelectRegion,
        }"
      />
      <react-component
        name="MoversSharedFields"
        props="{
          orderID: $ctrl.order.id,
          orderType: $ctrl.order.type,
          overrideMovers: $ctrl.order.override_movers,
          onOverrideMovers: $ctrl.onOverrideMovers,
          movers: $ctrl.order.movers,
          onMovers: $ctrl.onMovers,
          moverOverrideReason: $ctrl.order.mover_override_reason,
          onMoverOverrideReason: $ctrl.onMoverOverrideReason,
          movingConfigurationID: $ctrl.order.moving_operation.moving_configuration_id
        }"
      />

      <div class="form-group">
        <label for="contact_name" class="control-label col-sm-2">Day-of Contact</label>
        <div class="col-sm-5">
          <input
            id="contact_name"
            name="order[contact][name]"
            type="text"
            placeholder="Name"
            class="form-control"
            ng-model="$ctrl.order.contact.name"
            input-format
            format-type="title-case"
          />
        </div>
        <div class="col-sm-5">
          <input
            id="contact_phone"
            name="order[contact][phone]"
            type="text"
            placeholder="Phone"
            class="form-control"
            ng-model="$ctrl.order.contact.phone"
            input-telephone-filter
          />
        </div>
      </div>

      <div class="form-group">
        <label for="order_other" class="control-label col-sm-2">Other</label>
        <div class="col-sm-10">
          <textarea
            id="order_other"
            name="order[other]"
            type="text"
            class="form-control"
            placeholder="Anything else you that is important for the order (large guard dog is actually harmless)."
            ng-model="$ctrl.order.other"
          ></textarea>
        </div>
      </div>
      <div
        ng-if="($ctrl.order.subtype !== 'onboarding' || $ctrl.order.reonboarding) && $ctrl.order.type !== 'selfstorage-shuttle' && $ctrl.order.type !== 'retail-delivery'"
        show-for-role="admin billing care dispatcher"
        class="form-group"
      >
        <labor-exception-input
          labor-exception="$ctrl.order.labor_exception"
          on-check="$ctrl.updateLaborException(value)"
          on-select="$ctrl.updateExceptionReason(value)"
        />
      </div>
      <div class="form-group" show-for-role="admin dispatcher" ng-if="$ctrl.order.type !== 'selfstorage-shuttle' && $ctrl.order.type !== 'retail-delivery'">
        <label for="order_follow_up_job" class="control-label col-sm-2">Follow up job</label>
        <div class="col-sm-1 col-auto">
          <input type="checkbox" id="order_follow_up_job" ng-model="$ctrl.order.follow_up_job" />
        </div>
        <div class="col-sm-4">
          <react-component
            name="PredecessorOrderSelector"
            props="{
              accountID: $ctrl.account.id,
              orderID: $ctrl.order.id,
              predecessorID: $ctrl.order.predecessor_id,
              disabled: !$ctrl.order.follow_up_job,
              onSelectPredecessorID: $ctrl.onSelectPredecessorID,
            }"
          />
        </div>
        <label for="follow_up_job_exception" class="control-label col-sm-2">Waive Follow-Up Fee?</label>
        <div class="col-sm-1 col-auto">
          <input 
            type="checkbox"
            id="follow_up_job_exception"
            ng-change="$ctrl.onFollowUpJobException($ctrl.order.follow_up_job_exception)"
            ng-model="$ctrl.order.follow_up_job_exception"
            ng-disabled="!$ctrl.order.follow_up_job"
          />
        </div>
        <div class="col-sm-3">
          <react-component
            name="SelectWithOther"
            props="{
              option: $ctrl.order.follow_up_job_exception_reason,
              onSelect: $ctrl.onFollowUpJobExceptionReason,
              options: ['Clutter Late Arrival', 'Slower Than Expected Throughput', 'Not Enough Truck Space'],
              disabled: !$ctrl.order.follow_up_job_exception,
              placeholder: '-- Reason --'
            }"
          />
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <h3 class="text-thin">Schedule</h3>
    <div class="row">
      <div class="col-sm-12">
        <div ng-if="$ctrl.schedulable()">
          <p ng-if="$ctrl.onboardingDate && $ctrl.order.type !== 'moving-move'" class="text-bold text-danger">
            Schedule after {{ $ctrl.onboardingDate }}.
          </p>
          <p ng-if="$ctrl.order.type === 'moving-move'" class="text-bold text-danger">
            Changing the date can affect labor rates and minimums. Please check the moving quote above after selecting a date.
          </p>
          <div class="schedule-container">
            <div class="form-group">
              <div class="col-sm-12">
                <order-availability
                  order="$ctrl.order"
                  timezone="$ctrl.order.region.tz"
                  subscriptions="$ctrl.account.subscriptions"
                  on-schedule-change="$ctrl.reschedule()"
                >
                </order-availability>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-1" ng-hide="$ctrl.has_schedule_override_permission()"></div>
              <label
                for="order_scheduled"
                class="control-label col-sm-1"
                ng-show="$ctrl.has_schedule_override_permission()"
                >Schedule Override</label
              >
              <div class="col-sm-11">
                <div class="form-inline" ng-if="$ctrl.has_schedule_override_permission()">
                  <div class="checkbox pad-rgt">
                    <input type="checkbox" id="order_scheduled_override" ng-model="$ctrl.order.scheduled_override" />
                  </div>
                  <div
                    id="order_scheduled"
                    class="form-control-static"
                    disabled="!$ctrl.order.scheduled_override"
                    input-date-time-with-time-zone
                    tz="$ctrl.order.region.tz"
                    min="$ctrl.MIN_SCHEDULED"
                    max="$ctrl.MAX_SCHEDULED"
                    value="$ctrl.order.scheduled"
                    on-change-callback="$ctrl.reschedule()"
                  ></div>
                  <react-component
                    name="SelectWithOther"
                    props="{
                      option: $ctrl.order.scheduled_override_reason,
                      onSelect: $ctrl.onScheduledOverrideReason,
                      options: [
                        'Building Restriction',
                        'Field Issue',
                        'Planned Subjob',
                        'Requested by the customer',
                        'Route Optimization',
                        'Simultaneous Order',
                        'This is a warehouse order',
                        'Unplanned Subjob',
                        'Warehouse Issue'
                      ],
                      disabled: !$ctrl.order.scheduled_override,
                      placeholder: 'Select Reason for Schedule Override'
                    }"
                  />
                </div>

                <div class="text-danger col-sm-6" ng-if="$ctrl.order.type === 'moving-move' && $ctrl.showMovingReschuledingWarning()">
                  <p>
                    If this order is rescheduled, please inform the customer that:
                  </p>
                  "{{$ctrl.moveRescheduleFeeDescription}}"
                </div>

                <div ng-if="$ctrl.order.type !== 'moving-move'">
                  <div class="text-danger" ng-if="$ctrl.order.rescheduled_at && !$ctrl.order.cardless">
                    This order has already been rescheduled by the customer. If this order is rescheduled again or
                    cancelled, customer will be charged a reschedule fee.
                  </div>
                  <div class="text-success" ng-if="!$ctrl.order.rescheduled_at || $ctrl.order.cardless">
                    Reschedule fee will be waived for this order.
                  </div>
                </div>

                <div ng-if="$ctrl.order.rescheduling">
                  <label class="control-label"
                    >Who requested the reschedule? <span class="text-danger">(Must Select One)</span></label
                  >
                  <div>
                    <label class="radio-inline">
                      <input
                        type="radio"
                        ng-model="$ctrl.order.rescheduler"
                        ng-value="$ctrl.customer"
                        name="order[rescheduler]"
                        id="order-rescheduler-customer"
                      />
                      Customer - {{ $ctrl.account.customer.name }}
                    </label>
                    <label class="radio-inline">
                      <input
                        type="radio"
                        ng-model="$ctrl.order.rescheduler"
                        ng-value="$ctrl.user"
                        name="order[rescheduler]"
                        id="order-rescheduler-user"
                      />
                      Clutter Employee
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="schedule-fallback" ng-if="!$ctrl.schedulable()">
          <div class="form-group flex center">
            <label for="order_scheduled_maintenance" class="control-label col-sm-1">Order Scheduled</label>
            <div class="col-sm-11">
              <div class="form-inline">
                <div
                  id="order_scheduled_maintenance"
                  class="form-control-static"
                  input-date-time-with-time-zone
                  tz="$ctrl.order.region.tz"
                  min="$ctrl.MIN_SCHEDULED"
                  max="$ctrl.MAX_SCHEDULED"
                  value="$ctrl.order.scheduled"
                  on-change-callback="$ctrl.reschedule()"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group flex center">
          <label for="order_pickup_deadline" class="control-label col-sm-1">Deadline (optional)</label>
          <div class="col-sm-11">
            <div
              id="order_pickup_deadline"
              input-date-time-with-time-zone
              tz="$ctrl.order.region.tz"
              min="$ctrl.MIN_SCHEDULED"
              max="$ctrl.MAX_SCHEDULED"
              value="$ctrl.order.pickup_deadline"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
