<div class="dialog">
  <p class="dialog-title">{{ $ctrl.incident.id ? "Edit" : "Report" }} Incident</p>
  <div class="dialog-contents">
    <div class="form-group">
      <label>Incident Type:</label>
      <select
        name="incident[kind]"
        class="form-control"
        ng-disabled="$ctrl.incident.id"
        ng-model="$ctrl.incident.kind"
        ng-options="kind as (kind | titleize) for kind in $ctrl.incident_kind_options"
      >
        <option value=""> - Kind - </option>
      </select>
    </div>

    <div ng-if="$ctrl.incident.kind === 'duplicate' && $ctrl.incident.parent_type == 'items'">
      <react-component
        name="DuplicateItemLinking"
        props="{
          accountID: $ctrl.resource.account_id,
          itemID: $ctrl.resource.id,
          originalItemID: $ctrl.resource.duplicate_of_id,
          onChange: $ctrl.onNewDuplicateItemLink,
        }"
      />
    </div>

    <div class="form-group">
      <label>Description of Incident:</label>
      <textarea name="incident[explanation]" class="form-control" ng-model="$ctrl.incident.explanation">
      </textarea>
    </div>

    <div class="form-group" ng-if="!$ctrl.incident.id">
      <label>Attach Files (Damage Only):</label>
      <table class="table">
        <tbody>
          <tr ng-repeat="upload in $ctrl.uploads">
            <td class="col-sm-2">
              <span class="thumbnail">
                <img width="240" height="240" ng-src="{{ upload.reader.result }}"/>
              </span>
            </td>
            <td class="coll-sm-8 text-left">
              {{ upload.file.name }}
              <div class="progress" ng-if="upload.progress">
                <div class="progress-bar" role="progressbar"
                  ng-class="{
                    'progress-bar-info': upload.state === 'uploading',
                    'progress-bar-success': upload.state === 'success',
                    'progress-bar-warning': upload.state === 'failure',
                  }"
                  ng-style="{ width: (upload.progress | percentage) }">
                </div>
              </div>
              <div ng-switch="upload.state" class="text-center">
                <span ng-switch-when="uploading">
                  <span ng-if="upload.progress">{{ upload.progress | percentage : { precision: 2 } }}</span>
                </span>
                <span ng-switch-when="success">Uploaded</span>
                <span ng-switch-when="failure">Failed</span>
              </div>
            </td>
            <td class="col-sm-2 text-right">
              <a class="btn btn-danger" ng-click="$ctrl.unattach(upload)">Remove</a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="teal-file-input">
        <label class="btn btn-primary" for="file">Add Files</label>
        <input name="incident[files]" id="file" type="file" accept="image/*" files multiple picked="$ctrl.attach($file, $reader)" />
      </div>
    </div>

    <div class="form-group" ng-if="!$ctrl.incident.id">
      <div>
        <label ng-if="$ctrl.resource.state !== 'flagged'">
          <input name="incident[confirmations]" type="checkbox" ng-model="$ctrl.confirmations.state" />
          I understand this will update from <strong>{{ $ctrl.resource.state }}</strong> to <strong>flagged</strong>.
        </label>
      </div>
    </div>
    <div class="dialog-actions">
      <button class="btn btn-default btn-lg" ng-click="$ctrl.cancel()" ng-disabled="$ctrl.saving">Cancel</button>
      <button class="btn btn-primary btn-lg" ng-click="$ctrl.save()" ng-disabled="$ctrl.saving || !$ctrl.savable()">Save</button>
    </div>

  </div>
</div>
