<table class="table table-striped" id="phone-calls-list">
  <thead>
    <tr>
      <th>ID</th>
      <th>Status</th>
      <th>Direction</th>
      <th>Agent</th>
      <th>Date</th>
      <th>Time</th>
      <th>Length</th>
      <th>Customer Phone</th>
      <th>Marketing Phone</th>
      <th>Listen</th>
      <th>Download</th>
      <th>Category</th>
    </tr>
  </thead>

  <tbody>
    <tr ng-repeat="phone_call in $ctrl.calls">
      <td>
        <a ui-sref="viewPhoneCall({id:phone_call.id})" class="btn btn-default">{{ phone_call.id || '-' }}</a>
      </td>
      <td>
        <span class="label label-default">{{ phone_call.status | titleize }}</span>
      </td>
      <td>{{ phone_call.direction | titleize }}</td>
      <td>
        <span ng-if="$ctrl.showAgent(phone_call)">{{
          (phone_call.conversations | last).user.name || '-'
        }}</span>
        <span ng-if="!$ctrl.showAgent(phone_call)">-</span>
      </td>
      <td>
        <span ng-if="phone_call.start_at">{{ phone_call.start_at | datetz : $ctrl.timezone : 'MM/dd/yyyy' : true }}</span>
        <span ng-if="!phone_call.start_at">-</span>
      </td>
      <td>
        <span ng-if="phone_call.start_at">{{ phone_call.start_at | datetz: $ctrl.timezone : 'h:mm a' }}</span>
        <span ng-if="!phone_call.start_at">-</span>
      </td>
      <td>
        <span ng-if="phone_call.duration">{{ phone_call.duration | toTime }}</span>
        <span ng-if="!phone_call.duration">-</span>
      </td>
      <td>
        <react-component ng-if="phone_call.phone" name="PhoneFormatter" props="{ number: phone_call.phone }" />
        <span ng-if="!phone_call.phone">-</span>
      </td>
      <td>
        <react-component ng-if="phone_call.system_phone" name="PhoneFormatter" props="{ number: phone_call.system_phone }" />
        <span ng-if="!phone_call.system_phone">-</span>
      </td>
      <td>
        {{ last_conversation=(phone_call.conversations | last); '' }}
        <span ng-if="last_conversation.playback_url">
          <react-component
            name="AudioListen"
            props="{
              src: last_conversation.playback_url,
              pageID: phone_call.id,
              agent: last_conversation.user && last_conversation.user.name,
              date: phone_call.start_at,
            }"
          />
        </span>
        <span ng-if="phone_call.voicemails[0].playback_url && phone_call.conversations.length === 0">
          <react-component
            name="AudioListen"
            props="{
              src: phone_call.voicemails[0].playback_url,
              pageID: phone_call.id,
              date: phone_call.start_at,
            }"
          />
        </span>
        <span
          ng-if="!last_conversation.playback_url && !(phone_call.voicemails[0].playback_url && phone_call.conversations.length === 0)"
          >-</span
        >
      </td>
      <td>
        <span ng-if="last_conversation.playback_url">
          <react-component
            name="AudioDownload"
            props="{
              src: last_conversation.playback_url
            }"
          />
        </span>
        <span ng-if="phone_call.voicemails[0].playback_url && phone_call.conversations.length === 0">
          <react-component
            name="AudioDownload"
            props="{
              src: phone_call.voicemails[0].playback_url
            }"
          />
        </span>
        <span
          ng-if="!last_conversation.playback_url && !(phone_call.voicemails[0].playback_url && phone_call.conversations.length === 0)"
          >-</span>
      </td>
      <td>
        <a
          ng-if="phone_call.lead.id && !phone_call.lead.account_id"
          ui-sref="editLead({ id: phone_call.lead.id })"
          class="btn btn-default"
        >
          Lead
        </a>
        <a
          ng-if="phone_call.lead.account_id"
          ui-sref="viewAccount({ id: phone_call.lead.account_id })"
          class="btn btn-default"
        >
          Account
        </a>
        <a
          ng-if="phone_call.dispatch_id"
          ui-sref="viewDispatch({ id: phone_call.dispatch_id })"
          class="btn btn-default"
        >
          Dispatch
        </a>
      </td>
    </tr>
  </tbody>
</table>
