<span>
  <span ng-if="$ctrl.order.moving">Moving</span>
  <span ng-if="$ctrl.order.moving && $ctrl.order.storage">& Pickup</span>
  <span
    ng-if="!$ctrl.order.moving && $ctrl.order.storage && $ctrl.order.type !== 'selfstorage-shuttle' && $ctrl.order.type !== 'retail-delivery'"
    >{{ $ctrl.order.type | titleize }}</span
  >
  <span ng-if="$ctrl.order.type === 'selfstorage-shuttle'">Self-Storage Shuttle</span>
  <span ng-if="$ctrl.order.type === 'retail-delivery'">Delivery</span>
  <span ng-if="$ctrl.order.type === 'taskorder'">Task Order</span>
  <span ng-if="$ctrl.order.type === 'disposal'">Disposal</span>
  <span>-</span>
  <span>{{ $ctrl.order.subtype | titleize }}</span>
  <span>{{ $ctrl.order.maintenance ? ' - Maintenance' : '' }}</span>
</span>
