query Dispatch($dispatchID: ID!, $orderFilterInput: OrderFilterInput) {
  dispatch(dispatchID: $dispatchID) {
    id
    arrival
    cutoff
    clockout
    region {
      id
      tz
    }
    orders(filter: $orderFilterInput) {
      id
      parentID
      scheduled
      internalScheduled
      name
      slaWindowEnd
      status
      expectedStartTime
      formattedArrivalWindow
      orderEventsExcludingNonLeadAssignments {
        id
        category
        eventName
        timestamp
        isBreakEvent
      }
      simulatedOrderEvents {
        id
        category
        eventName
        timestamp
        isBreakEvent
      }
      services {
        id
        type
        cuft
        estimatedCuft
        estimatedCuftPerHourPerMover
        cuftPerHourPerMover
        started
      }
    }
  }
}
