<div class="incidents panel">
  <div class="panel-heading">
    <h3 class="panel-title">Incidents</h3>
  </div>
  <div class="panel-body">
    <table class="table table-striped" ng-if="$ctrl.incidents.$resolved && $ctrl.incidents.length">
      <thead>
        <tr>
          <th class="col-sm-2 text-left">Date</th>
          <th class="col-sm-2 text-left">Reporter</th>
          <th class="col-sm-3 text-left">Explanation</th>
          <th class="col-sm-3 text-left">Images</th>
          <th class="col-sm-1 text-left">Last Known Location</th>
          <th class="col-sm-1 text-left">Duplicate Of</th>
          <th class="col-zero text-left">Status</th>
          <th class="col-zero text-left"></th>
        </tr>
      </thead>
      <tbody>
        <tr ng-repeat="incident in $ctrl.incidents">
          <td class="col-sm-2 text-left">{{ $ctrl.formattedDateTime(incident.timestamp) }}</td>
          <td class="col-sm-2 text-left">{{incident.user.name}}</td>
          <td class="col-sm-3 text-left">
            <strong>{{incident.kind}}</strong> <span>{{incident.explanation}}</span>
          </td>
          <td class="col-sm-3 text-left">
            <div class="row">
              <div class="col-lg-2 col-md-4 col-sm-6" ng-repeat="image in incident.images">
                <a class="thumbnail" ng-click="$ctrl.lightbox(image)">
                  <img ng-src="{{image | resize : 320 : 320}}" class="img-responsive" />
                </a>
              </div>
            </div>
          </td>
          <td class="col-sm-1 text-left">
            <view-location ng-if="incident.last_known_location" location="incident.last_known_location" show-warehouse="true" />
          </td>
          <td class="col-sm-1 text-left">
            <div ng-if="incident.kind === 'duplicate' && $ctrl.resource.duplicate_of_id">
              <a ui-sref="viewItem({ account_id: $ctrl.resource.account_id, id: $ctrl.resource.duplicate_of_id })" class="text-primary">
                <react-component name="ItemBarcode" props="{accountID: $ctrl.resource.account_id, id: $ctrl.resource.duplicate_of_id}" />
              </a>
            </div>
          </td>
          <td class="col-zero col-nowrap">
            <resource-state-label state="incident.state" resource="incident" ></resource-state-label>
          </td>
          <td class="col-zero col-nowrap">
            <a href="" class="btn btn-default" ng-if="!$ctrl.readOnly" ng-click="$ctrl.edit(incident)">Edit</a>
            <a href="" class="btn btn-default" ng-click="$ctrl.history(incident)">History</a>
          </td>
        </tr>
      </tbody>
    </table>

    <p ng-if="$ctrl.incidents.$resolved && !$ctrl.incidents.length" class="text-center">
      No incidents have been reported for this {{ $ctrl.kind }}.
    </p>

    <spinner visible="!$ctrl.incidents.$resolved"></spinner>
  </div>
  <div class="panel-footer" ng-if="$ctrl.hasReportIncidentRole && !$ctrl.readOnly">
    <div class="clearfix">
      <a class="btn btn-default pull-right" href="#" ng-click="$ctrl.new()">
        Report Incident
      </a>
    </div>
  </div>
</div>
