<div class="panel">
  <div class="panel-heading">
    <div class="panel-control">
      <button class="btn btn-default" data-target="#collapse-{{ ::$id }}" data-toggle="collapse" ng-click="$ctrl.toggle()">
        <i class="fa" ng-class="$ctrl.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"></i>
      </button>
    </div>
    <h3 class="panel-title" ng-transclude="title"></h3>
  </div>
  <div class="collapse in" id="collapse-{{ ::$id }}">
    <div class="panel-body">
      <div class="clearfix" ng-transclude="content"></div>
    </div>
    <div class="panel-footer">
      <div class="clearfix" ng-transclude="actions"></div>
    </div>
  </div>
</div>
