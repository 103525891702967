<span>
  Uncancel and Reschedule to:
  <div id="uncancel_scheduled"
    class="form-control-static"
    input-date-time-with-time-zone
    tz="$ctrl.order.region.tz"
    value="$ctrl.scheduled_at">
  </div>
  <button ng-click="$ctrl.uncancel()" class="btn btn-danger" ng-disabled="$ctrl.disableUncancel()">Uncancel</button>
</span>
