query InsufficientSKUTruckCuft($input: Logistics__InsufficientSKUTruckCuftInput!) {
  insufficientSkuTruckCuft(input: $input) {
    validForKnownDimensions
    skusWithoutDimensions {
      id
      value
    }
    suggestedLoads {
      suggestedExpectations {
        sku {
          id
          value
        }
        quantity
      }
    }
  }
}
