<div
  class="dispatcher-order"
  drag="$ctrl.mode === 'modifying'"
  dragstart="$ctrl.dragstart({ order: $ctrl.order })"
  dragmove="$ctrl.dragmove({ order: $ctrl.order, $event: $event })"
  dragend="$ctrl.dragend({ order: $ctrl.order })"
  ng-class="::[
    'dispatcher-order-' + ($ctrl.order.moving ? ($ctrl.order.long_distance_moving ? 'long-distance-moving' : 'moving') : $ctrl.order.type),
    'dispatcher-order-' + $ctrl.order.subtype,
    'dispatcher-order-' + $ctrl.sizing(),
    'dispatcher-order-' + $ctrl.order.state,
    { 'dispatcher-order-has-warehouse' : $ctrl.order.account_warehouses }]"
>
  <div class="dispatcher-order-content" ng-class="{ 'dispatcher-error': $ctrl.errors() }">
    <div ng-if="$ctrl.whiteGloveTag()" class="label label-info">
      {{$ctrl.whiteGloveTag()}}
    </div>
    <div ng-if="$ctrl.extendedServiceAreaTag()" class="label label-info">
      {{$ctrl.extendedServiceAreaTag()}}
    </div>
    <div ng-if="$ctrl.order.long_distance_return" class="label label-info">
      Long-Distance
    </div>
    <div class="dispatcher-order-preview">
      <a
        target="_blank"
        ui-sref="viewOrder({ account_id: $ctrl.order.account.id, id: $ctrl.order.id })"
        ng-switch="::$ctrl.order.type"
      >
        <i ng-switch-when="return" class="fa fa-sign-out"></i>
        <i ng-switch-default class="fa fa-sign-in"></i>
      </a>
    </div>
    <div class="dispatcher-order-customer dispatcher-order-collapse text-overflow">
      <span ng-bind="::$ctrl.getCustomerName($ctrl.order.account)"></span>
      <strong ng-if="$ctrl.order.attached_service_display_name" style="color:#60A2DD">(Sim-Job {{ $ctrl.order.main_service_display_name }})</strong>
      <strong ng-if="!$ctrl.order.attached_service_display_name">({{ $ctrl.order.main_service_display_name }})</strong>
    </div>
    <div class="dispatcher-order-separator">-</div>
    <div class="dispatcher-order-address dispatcher-order-collapse text-overflow">
      {{ ::($ctrl.order.address.neighborhood || $ctrl.order.address.city) }}
    </div>
    <div ng-if="$ctrl.order.account_warehouses" class="dispatcher-order-separator">-</div>
    <div
      ng-if="$ctrl.order.account_warehouses"
      class="dispatcher-order-warehouse dispatcher-order-collapse text-overflow"
    >
      Warehouses: {{ $ctrl.order.order_warehouses || $ctrl.order.account_warehouses }}
    </div>
    <div class="dispatcher-order-separator">-</div>
    <div class="dispatcher-order-scheduled dispatcher-order-collapse">{{ $ctrl.formattedTime() }}</div>
    <div class="dispatcher-order-separator">-</div>
    <div class="dispatcher-order-estimated dispatcher-order-collapse">
      <a class="dispatcher-link" href="" ng-click="::$ctrl.estimate()">
        <i class="fa fa-clock-o"></i>
        <span ng-if="::$ctrl.order.estimated_duration">
          <span ng-if="$ctrl.order.moving && $ctrl.order.moving_operation.estimated_duration">
            <duration
              value="$ctrl.order.estimated_duration + $ctrl.order.moving_operation.estimated_duration"
              units="'seconds'"
              precision="2"
            ></duration>
          </span>
          <span ng-if="!$ctrl.order.moving">
            <duration value="$ctrl.order.estimated_duration" units="'seconds'" precision="2"></duration>
          </span>
        </span>
        <span ng-if="::!$ctrl.order.estimated_duration"> Add</span>
      </a>
      <span ng-if="$ctrl.errors() === 'rescheduled'" class="label label-danger">{{ $ctrl.errors() }}</span>
      <span ng-if="$ctrl.order.multi_day" class="label label-primary">
        Day {{ $ctrl.order.multi_day_order_position }} of {{ $ctrl.order.multi_day_order_count }}
      </span>
      <span ng-if="$ctrl.order.attached_service_display_name" class="label label-primary">
        Attached {{ $ctrl.order.attached_service_display_name }}
      </span>
      <span
        ng-if="!$ctrl.errors()"
        class="label"
        ng-class="::$ctrl.order.state === 'canceled' ? 'label-danger' : 'label-default'"
        >{{::$ctrl.order.state}}</span
      >
      <span ng-if='$ctrl.order.type === "selfstorage-shuttle"' class="label label-default">
        <react-component
          name="SelfStorageShuttleFacilityAddress"
          props="{ shuttleID: $ctrl.order.id, nameOnly: true }"
        />
      </span>
    </div>
    <div class="dispatcher-order-movers dispatcher-order-collapse">
      <i
        ng-if="::$ctrl.isWarehouseOrder($ctrl.order)"
        data-toggle="tooltip"
        data-placement="right"
        title="Warehouse order"
        class="fa fa-building"
        aria-hidden="true"
      >
      </i>
      <react-component
        name="DispatcherMoverCountIcon"
        props="{ movers: $ctrl.order.movers, overrideReason: $ctrl.order.mover_override_reason }"
      />
    </div>
  </div>
</div>
