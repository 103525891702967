#import "@admin/fragments/estimation_category.gql"
#import "@admin/fragments/room_category.gql"
#import "@admin/fragments/pickup_inventory.gql"
#import "@admin/fragments/return_inventory.gql"

query OrderDetails($orderID: ID!) {
  estimationCategories {
    ...estimationCategory
  }
  roomCategories {
    ...roomCategory
  }
  order(orderID: $orderID) {
    services {
      id
      type
      subtype
      estimatedCuft
      ...pickupInventory
      ...returnInventory
    }
  }
}
