import React from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoWindow } from 'react-google-maps';
import { lowerCase } from 'lodash';
import moment from 'moment';
import { isOrderHoveredOrSelected, isOrderSelected } from '../utils';
import { layoutEventSystem } from '../propTypes';
import { PATH_SCALE, PATH_SCALE_FOCUS, PATH_ANCHOR } from '../contants';
import { checkCircle, house } from '../../../../paths';

const DashboardOrdersMarkers = ({
  orders,
  fillColor,
  strokeOpacity,
  fillOpacity,
  strokeColor,
  dispatch,
  onOrderHoverOut,
  onOrderClick,
  onOrderHover,
  ...eventSystem
}) =>
  orders.map((o, orderIndex) => (
    <Marker
      icon={{
        path: lowerCase(o.state) === 'completed' ? checkCircle : house,
        fillColor,
        strokeWeight: isOrderHoveredOrSelected(eventSystem, o.id) ? 2 : 1,
        scale: isOrderHoveredOrSelected(eventSystem, o.id) ? PATH_SCALE_FOCUS : PATH_SCALE,
        fillOpacity,
        strokeOpacity,
        strokeColor,
        anchor: PATH_ANCHOR,
      }}
      onMouseOut={onOrderHoverOut}
      onClick={() => onOrderClick(o.id)}
      onMouseOver={() => onOrderHover(o.id)}
      key={`${dispatch.id}:order:${o.id}`}
      position={{
        lat: Number(o.address.latitude),
        lng: Number(o.address.longitude),
      }}
    >
      {isOrderSelected(eventSystem, o.id) && (
        <InfoWindow onCloseClick={() => onOrderClick(null)}>
          <div>
            {o.id}
            <h4 style={{ fontWeight: 'normal' }}>
              {orderIndex + 1}. <strong>{o.account.customers[0].name}</strong> {moment(o.scheduled).format('h:mm a')}
            </h4>
            {o.address.line1} {o.address.line2} <br />
            {o.address.city}, {o.address.zip}
          </div>
        </InfoWindow>
      )}
    </Marker>
  ));

DashboardOrdersMarkers.propTypes = {
  orders: PropTypes.array,
  fillColor: PropTypes.string,
  strokeOpacity: PropTypes.number,
  fillOpacity: PropTypes.number,
  strokeColor: PropTypes.string,
  dispatch: PropTypes.object,
  ...layoutEventSystem,
};

export { DashboardOrdersMarkers };
