import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import { uniqBy } from 'lodash';
import { PATH_SCALE, PATH_ANCHOR } from '../contants';
import { building } from '../../../../paths';

const DashboardWarehouseMarker = ({ dispatches }) =>
  uniqBy(
    dispatches.map((dispatch) => dispatch.warehouse),
    'id',
  ).map((warehouse) => (
    <Marker
      icon={{
        path: building,
        fillColor: 'grey',
        strokeWeight: 1,
        fillOpacity: 0.7,
        strokeColor: 'grey',
        scale: PATH_SCALE,
        anchor: PATH_ANCHOR,
      }}
      key={`warehouse:${warehouse.id}`}
      position={{
        lat: Number(warehouse.address.latitude),
        lng: Number(warehouse.address.longitude),
      }}
    />
  ));

DashboardWarehouseMarker.propTypes = {
  dispatches: PropTypes.array.isRequired,
};

export { DashboardWarehouseMarker };
