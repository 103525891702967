<div ng-if="$ctrl.classification.type === 'return' && $ctrl.classification.subtype == 'final'">
  <div class="panel">
    <div class="panel-heading">
      <h4 class="panel-title">Cancellation</h4>
    </div>
    <div class="panel-body">
      <react-component
        name="AccountCancelReasonSelector"
        props="{
          reason: $ctrl.order.account_cancel_intent.reason,
          setReason: $ctrl.setAccountCancelIntentReason,
        }"
      />
    </div>
  </div>
</div>
