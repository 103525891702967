<div class="images-panel panel">
  <div class="panel-heading">
    <h3 class="panel-title">Images</h3>
  </div>
  <div class="panel-body">
    <div ng-repeat="image in $ctrl.images" ng-style="$ctrl.styles">
      <a href="{{ image.source }}" target="_blank" >
        <img ng-src="{{ image | resize : 320 : 320 }}" alt="attached image"/>
      </a>
    </div>
  </div>
</div>
