<div>
  <table class="table">
    <tbody>
    <tr ng-repeat="upload in $ctrl.uploads">
      <td class="col-sm-2">
              <span class="thumbnail">
                <img ng-if="upload.url" width="240" height="240" ng-src="{{ upload | resize : 240 : 240 }}"/>
                <img ng-if="!upload.url" width="240" height="240" ng-src="{{ upload.reader.result }}"/>
              </span>
      </td>
      <td class="coll-sm-8 text-left">
        {{ upload.file.name }}
        <div class="progress" ng-if="upload.progress">
          <div class="progress-bar" role="progressbar"
               ng-class="{
                    'progress-bar-info': upload.state === 'uploading',
                    'progress-bar-success': upload.state === 'success',
                    'progress-bar-warning': upload.state === 'failure',
                  }"
               ng-style="{ width: (upload.progress | percentage) }">
          </div>
        </div>
        <div ng-switch="upload.state" class="text-center">
                <span ng-switch-when="uploading">
                  <span ng-if="upload.progress">{{ upload.progress | percentage : { precision: 2 } }}</span>
                </span>
          <span ng-switch-when="success">Uploaded</span>
          <span ng-switch-when="failure">Failed</span>
        </div>
      </td>
      <td class="col-sm-2 text-right">
        <a class="btn btn-danger" ng-click="$ctrl.unattach(upload)">Remove</a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
