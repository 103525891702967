import React from 'react';
import { lowerCase, map } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Badge, Col, Radio } from '@shared/components/bootstrap';
import { withRouterState } from '../../../helpers/routes';
import { getOrderStartTime } from '../../../models/get_order_time_estimates';

function getClassNamesForOrder(order) {
  const names = [];
  if (lowerCase(order.state) === 'canceled') {
    names.push('order-disabled');
  }
  return names;
}

const orderIsActive = (order) => (lowerCase(order.state) !== 'canceled' ? order.started && !order.ended : false);

const getItemsCount = (order) => order.items_count;

const pluralize = (word, count) => (count !== 1 ? `${word}s` : word);

const bottomMessage = (order) => {
  if (order.type === 'pickup') {
    return '';
  }
  return `${getItemsCount(order)} ${pluralize('item', getItemsCount(order))}`;
};

const RoutePanelOrderRowBase = ({ id, $state, order, selected, onClick, onMouseOver, onMouseOut, hovered, index }) => (
  <div
    id={id}
    className={classNames('order-row', getClassNamesForOrder(order), {
      'order-selected': selected,
      'order-hovered': hovered,
      'order-alt-row': index % 2,
    })}
    onClick={() => onClick(order.id)}
    onMouseOver={() => onMouseOver(order.id)}
    onMouseOut={() => onMouseOut(order.id)}
  >
    <Col sm={3}>
      <Radio
        name="focusedOrder"
        checked={selected}
        inline
        onChange={() => {
          onClick(order.id);
        }}
      >
        {moment.tz(getOrderStartTime(order), order.region.tz).format('h:mm a')}
      </Radio>
    </Col>
    <Col sm={6}>
      <h4>
        <a
          target="_blank"
          className="link-hover"
          href={$state && $state.href('viewOrder', { account_id: order.account.id, id: order.id })}
        >
          {order.account.customers[0].name}
          <i className="fa fa-external-link" style={{ marginLeft: '6px' }} />
        </a>
      </h4>
      <Col sm={6}>{[order.type, ...map(order.sibling_orders, 'type')].join(' / ')}</Col>
      <Col sm={6}>{bottomMessage(order)}</Col>
    </Col>
    <Col sm={3} className="order-state">
      {!selected && orderIsActive(order) ? <Badge>{order.labor_state}</Badge> : order.labor_state}
    </Col>
  </div>
);

const RoutePanelOrderRow = withRouterState(RoutePanelOrderRowBase);

RoutePanelOrderRow.propTypes = {
  // DOM ID - not Order Id
  id: PropTypes.string.isRequired,
  order: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  hovered: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default RoutePanelOrderRow;
