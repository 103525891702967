<div class="dispatcher-assignments">
  <div class="dispatcher-assignment"
       ng-repeat="assignment in $ctrl.dispatch.assignments" ng-if="!assignment._destroy"
       ng-class="['dispatcher-assignment-' + assignment.role]">
    <div class="dispatcher-assignment-brick"
         ng-click="$ctrl.select(assignment)"
         ng-mouseover="$ctrl.showHoursWorked(assignment)"
         ng-mouseleave="$ctrl.hideHoursWorked()">
      <div class="dispatcher-assignment-text">{{ assignment.user.name | initials }}</div>
      <div class="dispatcher-assignment-badge" ng-if="assignment.role === 'lead'">⭐️</div>
      <div class="dispatcher-assignment-badge" ng-if="assignment.role === 'driver'">🚛</div>
      <div class="dispatcher-assignment-streak" ng-if="$ctrl.activeAssignment === assignment">
        <div>{{ assignment.user.name }}</div>
      </div>
    </div>
    <div class="dispatcher-panel"
         ng-if="$ctrl.selected(assignment)">
      <div>
        <div class="dispatcher-panel-banner">
          <div class="form-inline">
            <strong ng-if="!$ctrl.editing">{{ assignment.user.name }}</strong>
            <span ng-if="!$ctrl.editing">({{ assignment.role | titleize }})</span>
            <input class="form-control" placeholder="Search" autofocus autosubmit="$ctrl.autosubmit(autocomplete)"
                   ng-if="$ctrl.editing"
                   ng-model="$ctrl.autocomplete"
                   ng-change="$ctrl.search($ctrl.autocomplete)"
                   ng-model-options="{ debounce: 100 }">
            <select class="form-control form-control-inline"
                    ng-if="$ctrl.editing"
                    ng-model="assignment.role">
              <option value="lead">Lead</option>
              <option value="driver">Driver</option>
              <option value="member">Member</option>
              <option value="trainee">Trainee</option>
            </select>
          </div>
        </div>
        <div class="dispatcher-panel-content" ng-if="$ctrl.editing">
          <div ng-repeat="section in $ctrl.sections">
            <div class="dispatcher-panel-section"
                 ng-class="'availability-' + section.style"
                 ng-if="section.users.length">{{ section.name }}</div>
            <div class="dispatcher-panel-option"
              ng-repeat="user in section.users"
              ng-click="$ctrl.reassign(assignment, user); $event.stopPropagation();"
              ng-bind-html="user.name | highlight : $ctrl.autocomplete | dispatchRoles : user">
            </div>
          </div>
        </div>
        <div class="dispatcher-panel-actions"
             ng-if="$ctrl.editing">
          <a class="btn btn-danger btn-fill"
             ng-click="$ctrl.remove(assignment); $event.stopPropagation()">Remove</a>
        </div>
      </div>
    </div>
  </div>
  <div class="dispatcher-assignment"
       ng-if="$ctrl.editing"
       ng-click="$ctrl.insert()">
    <a href="#" class="dispatcher-assignment-brick dispatcher-assignment-plus">
      <div class="dispatcher-assignment-text">+</div>
    </a>
  </div>
  <div class="dispatcher-overlay"
       ng-if="$ctrl.selection"
       ng-click="$ctrl.deselect()">
  </div>
</div>
