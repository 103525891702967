<h3 class="text-thin">Origin Address</h3>
<react-component
  name="AddressSelector"
  props="{
    selection: $ctrl.order.moving_operation.origin_address,
    account: $ctrl.account,
    showAccountAddresses: $ctrl.showAccountAddresses,
    onSelect: $ctrl.originAddressSelected,
    potentiallyInvalid: $ctrl.originPotentiallyInvalid,
    warehouseReturn: $ctrl.warehouseReturn,
    onWarehouseSelect: $ctrl.onWarehouseSelect,
    onGeocode: $ctrl.originGeocoded,
  }"
/>

<h3 class="text-thin">Destination Address</h3>
<react-component
  name="AddressSelector"
  props="{
    selection: $ctrl.order.moving_operation.destination_address,
    account: $ctrl.account,
    showAccountAddresses: $ctrl.showAccountAddresses,
    onSelect: $ctrl.destinationAddressSelected,
    potentiallyInvalid: $ctrl.destinationPotentiallyInvalid,
    warehouseReturn: $ctrl.warehouseReturn,
    onWarehouseSelect: $ctrl.onWarehouseSelect,
    onGeocode: $ctrl.originGeocoded,
  }"
/>
