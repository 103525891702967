<react-component
  name="AddressSelector"
  props="{
    selection: $ctrl.order.address,
    account: $ctrl.account,
    showAccountAddresses: $ctrl.showAccountAddresses,
    onSelect: $ctrl.selected,
    potentiallyInvalid: $ctrl.potentially_invalid,
    warehouseReturn: $ctrl.warehouseReturn,
    onWarehouseSelect: $ctrl.onWarehouseSelect,
    onGeocode: $ctrl.geocoded,
  }"
/>
