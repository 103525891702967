<table class="table table-striped" id="warehouse-claimers">
    <thead>
      <tr>
        <th class="text-left">Task Kind</th>
        <th class="text-left">Role</th>
        <th class="text-left">Name</th>
        <th class="text-left">Title</th>
        <th class="text-left">Serial Code</th>
        <th class="text-left">Started</th>
        <th class="text-left">Completed</th>
        <th class="text-left">Unlocked</th>
        <th class="text-left">Unlocked By</th>
        <th class="col-sm-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr ng-repeat="warehouse_claimer in $ctrl.claimers">
        <td class="text-left">
          {{ warehouse_claimer.kind | titleize }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.is_claimer ? 'Claimer' : 'Teammate' }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.user.name }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.user.hrs_title }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.serial_code }}
        </td>
        <td class="text-left">
          {{ (warehouse_claimer.created_at | datetz : warehouse_claimer.time_zone : 'medium') }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.completed_at ? (warehouse_claimer.completed_at | datetz : warehouse_claimer.time_zone : 'medium') : '' }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.canceled_at ?  (warehouse_claimer.canceled_at | datetz : warehouse_claimer.time_zone : 'medium') : '' }}
        </td>
        <td class="text-left">
          {{ warehouse_claimer.canceler.name }}
        </td>
        <td class="col-sm-2" >
          <a
            class="btn btn-danger"
            ng-click="$ctrl.cancel(warehouse_claimer.id)"
            ng-if="warehouse_claimer.completed_at === null && warehouse_claimer.canceled_at === null"
          >
            {{ warehouse_claimer.is_claimer ? 'Unlock' : 'Cancel' }}
          </a>
        </td>
      </tr>
    </tbody>
</table>
