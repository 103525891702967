<table class="table">
  <tr ng-repeat="item in $ctrl.items track by item.id">
    <td class="col-nowrap col-zero text-left">
      <img ng-src="{{ item.images | hero | resize:480:480 }}" width=40 height=40 />
    </td>
    <td class="col-fill col-nowrap text-left">
      <item-packaging-fields item="item" ng-if="$ctrl.mode === 'package'"></item-packaging-fields>
      <span ng-if="$ctrl.group(item)">
        <i class="fa fa-link"></i>
        <span>to</span>
        <span>
          <react-component
            name="CategoryFormatter"
            props="{ resource: $ctrl.group(item) }"
          />
        </span>
        <span>#{{ $ctrl.group(item).id }}</span>
        <span ng-if="item.packaging">
          <span>as</span>
            <react-component
              name="ItemMaterialCategoryPreview"
              props="{ item: item }"
            />
            <react-component
              name="ItemPartCategoryPreview"
              props="{ item: item }"
            />
        </span>
      </span>
    </td>
    <td class="col-nowrap col-zero text-right">
      <react-component name="ItemMetadata" props="{ item: item }" />
    </td>
  </tr>
</table>
