<div ng-controller="CheckoutsNewController">
  <form role="form">
    <div class="lead-form-container">
      <div ng-include="'partials/checkouts/_fields.html'"></div>
    </div>
  </form>

  <div ng-if="lead.id">
    <react-component name="CustomerTicketPanel"
      props="{
        customerTicketableID: lead.id,
        customerTicketableType: 'Lead'
      }"
    />
    <react-component name="TicketsPanel" props="{ ticketable: { id: lead.id, type: 'LEAD' } }"/>
    <messages recipient-type="'Lead'" recipient="lead"></messages>
    <react-component name="HistoriesPanel" props="{ type: 'LEAD', id: lead.id }" />
  </div>
</div>
