query InsufficientSKUInventory($input: Logistics__InsufficientSKUInventoryInput!) {
  insufficientSkuInventory(input: $input) {
    id
    value
    storedCount
    upcomingLoadsNeedingSku {
      id
      number
      fulfillmentExpectations {
        id
        quantity
        sku {
          id
        }
      }
    }
  }
}
