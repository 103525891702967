<h1 class="page-header text-overflow">
  <ol class="breadcrumb">
    <li><a ui-sref="accounts()">Accounts</a></li>
    <li ng-if="$ctrl.account.id">
      <a ui-sref="viewAccount({ id: $ctrl.account.id })"
        >#{{ $ctrl.account.number }} ({{ $ctrl.account.customer.name }})
      </a>
    </li>
    <li ng-if="$ctrl.account.id && $ctrl.order.id">
      <a ui-sref="viewOrder({ account_id: $ctrl.account.id, id: $ctrl.order.id })">#{{ $ctrl.order.number }}</a>
    </li>
    <li ng-if="$ctrl.account.id && $ctrl.order.id" class="active">Edit</li>
    <li ng-if="$ctrl.account.id && !$ctrl.order.id" class="active">
      {{ $ctrl.orderTypeFormatted() | titleize }} - New
    </li>
  </ol>
</h1>

<form role="form">
  <div class="panel">
    <div class="panel-heading">
      <h3 class="panel-title">Order » {{ $ctrl.orderTypeFormatted() | titleize }}</h3>
    </div>

    <div class="panel-body">
      <order-address-selector
        ng-if="$ctrl.order.type !== 'moving-move'"
        show-account-addresses="$ctrl.order.external || $ctrl.order.type === 'return'"
        account="$ctrl.account"
        order="$ctrl.order"
        managing="$ctrl.managing"
        warehouse-return="$ctrl.warehouseReturn()"
      />
      <order-moving-address-selectors
        ng-if="$ctrl.order.type === 'moving-move'"
        account="$ctrl.account"
        order="$ctrl.order"
        managing="$ctrl.managing"
      />

      <hr />

      <div ng-if="$ctrl.order.type === 'moving-move'">
        <react-component name="MovingAppointmentDetails" props="{ orderID: $ctrl.order.id }" />
      </div>

      <div ng-if="$ctrl.type === 'pickup'">
        <h3 class="text-thin">Inventory List</h3>

        <react-component
          name="OrderInventorySection"
          props="{
            accountID: $ctrl.account.id,
            orderID: $ctrl.order.id,
            onChange: $ctrl.onInventory,
            address: $ctrl.order.address,
            onCuftCalculation: $ctrl.onEstimateCubicFootage,
            onMoverCountUpdate: $ctrl.onMoverCountUpdate,
            subtype: $ctrl.order.subtype,
          }"
        />

        <hr />
      </div>

      <div ng-if="$ctrl.type === 'selfstorage-shuttle' && !$ctrl.order.id">
        <h3 class="text-thin">Which Reservation?</h3>

        <react-component
          name="SelfStorageFacilityReservationSelector"
          props="{
            onChange: $ctrl.onReservationSelection,
            reservation: $ctrl.order.reservation
          }"
        />

        <hr />
      </div>

      <div ng-if="$ctrl.warehouseReturn()">
        <h3 class="text-thin">Which Warehouse Return Service Type?</h3>

        <react-component
          name="WarehouseReturnServiceTypeSelector"
          props="{
            onChange: $ctrl.onWarehouseReturnServiceTypeSelection,
            serviceType: $ctrl.order.service_type
          }"
        />

        <hr />
      </div>

      <order-shared-fields
        order="$ctrl.order"
        account="$ctrl.account"
        onboarding-date="$ctrl.onboardingDate"
        warehouse-return="$ctrl.warehouseReturn()"
      />
    </div>
  </div>

  <div ng-if="$ctrl.order.external && $ctrl.order.subtype === 'onboarding' || $ctrl.isReonboarding()">
    <react-component
      name="SubscribePanel"
      props="{
        zip: $ctrl.order.address.zip,
        cuft: $ctrl.estimatedCUFT,
        accountID: $ctrl.account.id,
        onChange: $ctrl.onSubscribe,
        reonboard: $ctrl.isReonboarding(),
      }"
    />
  </div>

  <div ng-if="$ctrl.type === 'return'">
    <div
      ng-if="$ctrl.order.state && !(['approved', 'pending', 'prepared'] | contains : $ctrl.order.state)"
      class="alert alert-info"
    >
      <div class="text-center" ng-if="!$ctrl.isAllowedToAddItemsToEnroute">
        Items can only be removed (not added) because the order is <strong>{{ $ctrl.order.state }}</strong>. Removed
        items not on pallets will generate a restock. Once items are removed they cannot be re-added!
      </div>
      <div
        class="text-center"
        ng-if="$ctrl.isAllowedToAddItemsToEnroute && $ctrl.order.state && $ctrl.order.state == 'enroute'"
      >
        Please ensure item can physically be loaded onto a truck when adding item to the order that is
        {{$ctrl.order.state}}
      </div>
    </div>

    <react-component
      name="InventoryFetcher"
      watch-depth="reference"
      props="{
        accountID: $ctrl.account.id,
        orderID: $ctrl.order.id,
        selections: $ctrl.order.items,
        onSelect: $ctrl.onItems,
      }"
    />

    <order-subtype-selector account="$ctrl.account" order="$ctrl.order" cancelable="!!$ctrl.order.allItemsSelected" />
  </div>

  <div class="panel">
    <div class="panel-body" ng-if="$ctrl.order.type !== 'retail-delivery'">
      <react-component
        name="CancellationDisclaimer"
        ng-if="$ctrl.order.type !== 'selfstorage-shuttle' && !$ctrl.warehouseReturn()"
        props="{
          accountID: $ctrl.account.id,
          orderType: $ctrl.order.type,
          orderSubtype: $ctrl.order.subtype,
          serviceType: $ctrl.order.service_type,
          checked: $ctrl.disclaimer,
          setDisclaimer: $ctrl.setCancellationDisclaimer
        }"
      />
      <div ng-if="$ctrl.order.unpaid_materials_bundle_amount">
        <label>
          <input type="checkbox" ng-model="$ctrl.disclaimerForMaterialsBundle" />
          I agree to pay {{ $ctrl.order.unpaid_materials_bundle_amount | currency }} for unlimited packing materials.
        </label>
      </div>
      <div ng-if="$ctrl.order.cancel_account">
        <react-component
          name="OrderTermContractsDisclaimer"
          props="{
            accountID: $ctrl.account.id,
            orderScheduledDate: $ctrl.order.scheduled,
            setIsEarlyTermination: $ctrl.setIsEarlyTermination,
            setDisclaimer: $ctrl.setEarlyTerminationDisclaimer,
          }"
        ></react-component>
      </div>
    </div>

    <div class="panel-footer text-right">
      <div>
        <span ng-if="!$ctrl.order.id" class="pull-left">
          <estimator-signature-affirmation on-check="$ctrl.setEstimatorAffirmation(value)" />
        </span>
      </div>
      <a
        ng-if="$ctrl.order.type === 'moving-move'"
        ui-sref="viewOrder({ account_id: $ctrl.account.id, id: $ctrl.order.id })"
        class="btn btn-default"
      >
        Cancel
      </a>
      <a
        ng-if="$ctrl.order.type !== 'moving-move'"
        ui-sref="viewAccount({ id: $ctrl.account.id })"
        class="btn btn-default"
      >
        Cancel
      </a>
      <span ng-switch="$ctrl.order.state">
        <input
          type="submit"
          class="btn btn-primary"
          value="Approve and Notify Customer"
          ng-switch-when="pending"
          ng-disabled="!$ctrl.savable()"
          ng-click="$ctrl.save()"
        />
        <input
          ng-if="$ctrl.order.id"
          type="submit"
          class="btn btn-primary"
          value="Update"
          ng-switch-default
          ng-disabled="!$ctrl.savable()"
          ng-click="$ctrl.save()"
        />
        <input
          ng-if="!$ctrl.order.id"
          type="submit"
          class="btn btn-primary"
          value="Schedule"
          ng-switch-default
          ng-disabled="!$ctrl.savable()"
          ng-click="$ctrl.save()"
        />
      </span>
    </div>
  </div>
</form>
