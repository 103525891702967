query ItemCategories {
  categories: itemCategories {
    id
    name
    parentID
    moveAndPackVisible
    sizingMetric {
      id
      name
      enumerations
      range
      unit
    }
  }
}
