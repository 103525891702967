<form name="dispatcherEstimation">
  <div class="dialog dispatcher-dialog dispatcher-estimation-form">
    <div class="dialog-contents">
      <div class="dialog-title">Adjust Estimate</div>
      <div class="dialog-description">
        <div class="row no-gutters">
          <div class="col-xs-2 text-left">
            <label>Override CuFt</label>
          </div>
          <div class="col-xs-10 text-left">
            <react-component
              name="EstimationCuftOverrideForm"
              props="{
                orderID: $ctrl.order.id,
                onChange: $ctrl.onCuftOverride
              }"
            />
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-xs-3 text-left">
            <label>Override Duration</label>
          </div>
          <div class="col-xs-9 text-left duration-override">
            <i class="fa info-icon" ng-show="$ctrl.predicted_duration_certainty == 'high'" uib-tooltip= "Estimated duration cannot be overwritten for this order">&#xf05a;</i>
            <input class="form-check-input estimated-duration-override-checkbox" type="checkbox" ng-model="$ctrl.form.estimated_duration_override" ng-disabled="$ctrl.predicted_duration_certainty === 'high'">
            <div class="input-group">
              <input name="dispatch[estimated_duration_hour]" class="form-control" type="number" ng-model="$ctrl.form.estimated_duration_hour" ng-change="$ctrl.updateEstimatedDuration()" ng-disabled="!$ctrl.form.estimated_duration_override || $ctrl.predicted_duration_certainty === 'high'">
              <span class="input-group-addon">hours</span>
            </div>
            <div class="input-group">
              <input name="dispatch[estimated_duration_minute]" class="form-control" type="number" ng-model="$ctrl.form.estimated_duration_minute" ng-change="$ctrl.updateEstimatedDuration()" ng-disabled="!$ctrl.form.estimated_duration_override || $ctrl.predicted_duration_certainty === 'high'">
              <span class="input-group-addon">minutes</span>
            </div>
            <react-component
              name="SelectWithOther"
              props="{
                option: $ctrl.form.estimated_duration_override_reason,
                onSelect: $ctrl.onReasonChange,
                options: ['Need to adjust estimated CuFt per Hour', 'Estimate seems off based on CuFt', 'Estimate seems off based on Item Categories', 'Estimate seems off based on Plan Size', 'Estimate seems off based on Address Details', 'This is a warehouse order', 'This is a 3PL order', 'There was no initial estimated duration'],
                disabled: !$ctrl.form.estimated_duration_override || $ctrl.predicted_duration_certainty === 'high',
                placeholder: 'Select Reason for Duration Override'
              }"
            />
            <input type="submit" value="Update" ng-click="$ctrl.update()" class="btn btn-default btn-primary form-update-button pull-right" ng-disabled="$ctrl.saving || !$ctrl.form.estimated_duration_override_reason" ng-show="$ctrl.form.estimated_duration_override">
            <input type="submit" value="Reset" ng-click="$ctrl.reset()" class="btn btn-default btn-primary form-update-button pull-right" ng-disabled="$ctrl.saving" ng-hide="$ctrl.form.estimated_duration_override">
          </div>
        </div>
      </div>

      <div class="dialog-description">
        <div class="dispatcher-dialog-notes">
          <div class="table-responsive">
            <table class="table table-striped">
              <tr>
                <th class="text-left col-nowrap col-zero">Start Time</th>
                <td  class="text-left col-fill">
                  <input type="time"
                         name="startTime"
                         step="60"
                         ng-min="$ctrl.min_start_time"
                         ng-max="$ctrl.max_start_time"
                         ng-model="$ctrl.start_time"
                         ng-model-options="{ timezone: $ctrl.utc_offset }"
                         ng-change="$ctrl.updateDispatchOffset()"
                         ng-disabled="$ctrl.mode !== 'modifying'"
                  />
                  <div class="error" ng-show="!dispatcherEstimation.startTime.$valid" style="color: red">Time must be between {{$ctrl.min_start_time_formatted}} and {{$ctrl.max_start_time_formatted}}</div>
                </td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero"> Estimated Job Duration</th>
                <td  class="text-left col-fill">{{ $ctrl.order_estimated_duration | duration }}</td>
              </tr>

               <tr ng-if="$ctrl.order.moving && $ctrl.order.moving_operation.estimated_duration">
                <th class="text-left col-nowrap col-zero">Estimated Travel Duration:</th>
                <td  class="text-left col-fill">{{ $ctrl.order.moving_operation.estimated_duration | duration }}</td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero">Type:</th>
                <td class="text-left col-fill"><order-summary order="$ctrl.order"></order-summary></td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero">Service Type:</th>
                <react-component name="FormattedServiceType" props="{ order: $ctrl.order }"/>
              </tr>

              <tr ng-if="$ctrl.order.pickup_deadline">
                <th class="text-left col-nowrap col-zero">Deadline:</th>
                <td class="text-left col-fill">{{ $ctrl.order.pickup_deadline  | datetz : $ctrl.order.region.tz : 'medium' }}</td>
              </tr>

              <tr ng-if="$ctrl.order.full_pack">
                <th class="text-left col-nowrap col-zero">Full Pack:</th>
                <td class="text-left col-fill">Yes</td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero">Items:</th>
                <td class="text-left col-fill">{{ $ctrl.order.items_count }}</td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero">Estimated CUFT:</th>
                <td class="text-left col-fill">
                  <react-component
                    name="OrderEstimatedCUFT"
                    props="{ orderID: $ctrl.order.id }"
                  />
                </td>
              </tr>

              <tr>
                <th class="text-left col-nowrap col-zero">Plan:</th>
                <td class="text-left col-fill">
                  <react-component
                    name="SubscriptionsSummary"
                    props="{ subscriptions: $ctrl.order.account.subscriptions }"
                  />
                </td>
              </tr>

              <tr ng-if="$ctrl.order.full_move_out || $ctrl.order.room_selections.length">
                <th class="text-left col-nowrap col-zero">Full Move-Out:</th>
                <td class="text-left col-fill">
                  {{$ctrl.order.full_move_out ? 'Yes' : 'No'}}
                </td>
              </tr>

              <tr ng-if="$ctrl.order.room_selections.length || $ctrl.order.property_square_footage">
                <th class="text-left col-nowrap col-zero">Rooms:</th>
                <td class="text-left col-fill">
                  <order-room-selections-and-square-footage-preview order="$ctrl.order"/>
                </td>
              </tr>

              <tr ng-if="$ctrl.order.moving && $ctrl.order.moving_operation.estimated_items.length">
                <th class="text-left col-nowrap col-zero">Moving Estimates:</th>
                <td class="text-left col-fill">
                  <estimated-item-list entries="$ctrl.order.moving_operation.estimated_items"></estimated-item-list>
                </td>
              </tr>

              <tr ng-if="$ctrl.order.storage && $ctrl.order.estimated_items.length">
                <th class="text-left col-nowrap col-zero">Storage Estimates:</th>
                <td class="text-left col-fill">
                  <estimated-item-list entries="$ctrl.order.estimated_items"></estimated-item-list>
                </td>
              </tr>

              <tr ng-if="$ctrl.order.material_selections.length || $ctrl.order.materials">
                <th class="text-left col-nowrap col-zero">Packing Notes:</th>
                <td class="text-left col-fill">
                  <materials-preview resource="$ctrl.order"></materials-preview>
                  <span class="preformatted">{{ $ctrl.order.materials }}</span>
                </td>
              </tr>

              <tr ng-if="$ctrl.order.other">
                <th class="text-left col-nowrap col-zero">Other:</th>
                <td class="text-left col-fill"><span class="preformatted">{{ $ctrl.order.other }}</span></td>
              </tr>

              <tr ng-if="!$ctrl.order.moving_operation">
                <th class="text-left col-nowrap col-zero">Address:</th>
                <td class="text-left col-fill">
                  <react-component name="AddressPreview" props="{ address: $ctrl.order.address }" />
                  <hr class="compact-hr" />
                  <react-component name="AddressDetailPreview" props="{ address: $ctrl.order.address }" />
                </td>
              </tr>

              <tr ng-if="$ctrl.order.moving_operation">
                <th class="text-left col-nowrap col-zero">Pickup Address:</th>
                <td class="text-left col-fill">
                  <react-component name="AddressPreview" props="{ address: $ctrl.order.moving_operation.origin_address }" />
                  <hr class="compact-hr" />
                  <react-component name="AddressDetailPreview" props="{ address: $ctrl.order.moving_operation.origin_address }" />
                </td>
              </tr>

              <tr ng-if="$ctrl.order.moving_operation">
                <th class="text-left col-nowrap col-zero">Delivery Address:</th>
                <td class="text-left col-fill">
                  <react-component name="AddressPreview" props="{ address: $ctrl.order.moving_operation.destination_address }" />
                  <hr class="compact-hr" />
                  <react-component name="AddressDetailPreview" props="{ address: $ctrl.order.moving_operation.destination_address }" />
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</form>
