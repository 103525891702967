query PalletTasks($destinationLocationID: ID!) {
  palletTasks(destinationLocationID: $destinationLocationID) {
    id
    completedAt
    canceledAt
    user {
      name
    }
    image {
      id
      size
      source
    }
  }
}
