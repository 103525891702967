<fieldset class="form-horizontal">
  <div class="row">
    <div class="col-xs-6 col-sm-5 col-md-4 col-lg-3">
      <div class="form-group" ng-repeat="option in $ctrl.options track by $index" ng-if="option.material_category.requestable">
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <input type="number" class="form-control" placeholder="0" min="0"
              id="material_category_{{ option.material_category.id }}"
              ng-model="option.quantity" ng-change="$ctrl.save()"
              input-format maxlength="3" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <label class="control-label" for="material_category_{{ option.material_category.id }}">
              {{ option.material_category.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-6 col-sm-7 col-md-8 col-lg-9">
      <textarea class="form-control form-control-tall" placeholder="Packing Instructions"
        ng-model="$ctrl.resource.materials" input-format format-type="sentence-case">
      </textarea>
    </div>
  </div>
</fieldset>
